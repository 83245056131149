import { getSysDictionaryByKey, getSysParamsByKey } from '@/api/sysParams'

// 系统参数与字典
const state = {
  grade: [], // 年级
  stuStatus: [], // 学籍状态
  teacherType: [],
  majorType: [],
  lengthOfSchoolingType: [], // 学制
  weekList: '日一二三四五六日',
  sexType: [{
    type: 1,
    name: '男'
  }, {
    type: 2,
    name: '女'
  }],
  yearItem: [{
    type: 1,
    name: '第一学年'
  }, {
    type: 2,
    name: '第二学年'
  }, {
    type: 3,
    name: '第三学年'
  }],
  payType: [{
    type: 1,
    name: '扣费'
  }, {
    type: 2,
    name: '现金'
  }, {
    type: 3,
    name: '刷卡'
  }, {
    type: 4,
    name: '汇款'
  }, {
    type: 5,
    name: '助贷'
  }, {
    type: 6,
    name: '减免'
  }, {
    type: 7,
    name: '退还'
  }, {
    type: 8,
    name: '移动支付'
  }, {
    type: 9,
    name: '移动支付:中行'
  }, {
    type: 10,
    name: '移动支付:工行'
  }, {
    type: 11,
    name: '其他'
  }],
  reviewStatus: [{
    paramValue: 1,
    paramName: '未审核',
    label: '审核中',
    btnType: 'primary'
  }, {
    paramValue: 2,
    paramName: '不通过',
    label: '不通过',
    btnType: 'danger'
  }, {
    paramValue: 3,
    paramName: '已审核',
    label: '已通过',
    btnType: 'success'
  }],
  greenRoadStatus: [{
    paramValue: 1,
    paramName: '贷款'
  }, {
    paramValue: 2,
    paramName: '分期'
  }, {
    paramValue: 3,
    paramName: '最低缴费'
  }, {
    paramValue: 4,
    paramName: '三次缴费'
  }],
  params: {} // 参数
}

const mutations = {
  // 设置年级
  setGradeList (state, data) {
    state.grade = data
  },
  // 设置学籍状态
  setStuStatus (state, data) {
    state.stuStatus = data
  },
  // 设置教师职务
  setTeacherType (state, data) {
    state.teacherType = data
  },
  setParams (state, data) {
    state.params = data
  },
  setMajorType (state, data) {
    state.majorType = data
  },
  setLengthOfSchoolingType (state, data) {
    state.lengthOfSchoolingType = data
  }
}

const actions = {
  // 获取系统字典
  getSystemDictionary ({ commit }, params) {
    return new Promise((resolve, reject) => {
      getSysDictionaryByKey(params).then(res => {
        switch (params) {
          case 'grade':
            commit('setGradeList', res.data)
            break
          case 'stuStatus':
            commit('setStuStatus', res.data)
            break
          case 'teacherType':
            commit('setTeacherType', res.data)
            break
          case 'majorType':
            commit('setMajorType', res.data)
            break
          case 'lengthOfSchoolingType':
            commit('setLengthOfSchoolingType', res.data)
            break
        }
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 获取系统字典
  getSystemParams ({ commit }, params) {
    return new Promise((resolve, reject) => {
      getSysParamsByKey(params).then(res => {
        commit('setParams', res.data)
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
